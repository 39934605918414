import React from 'react'
import { Carousel } from 'react-carousel-minimal'
import './Head.css'
import Grid from '@mui/material/Grid' // Grid version 1
import TypeWriterEffect from 'react-typewriter-effect'
import {motion} from 'framer-motion'
import { Button } from '@mui/material'

const Head = () => {
  const data = [
    {
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/GoldenGateBridge-001.jpg/1200px-GoldenGateBridge-001.jpg',
      caption: 'San Francisco',
    },
    {
      image:
        'https://cdn.britannica.com/s:800x450,c:crop/35/204435-138-2F2B745A/Time-lapse-hyper-lapse-Isle-Skye-Scotland.jpg',
      caption: 'Scotland',
    },
    {
      image:
        'https://static2.tripoto.com/media/filter/tst/img/735873/TripDocument/1537686560_1537686557954.jpg',
      caption: 'Darjeeling',
    },
    {
      image:
        'https://upload.wikimedia.org/wikipedia/commons/thumb/1/16/Palace_of_Fine_Arts_%2816794p%29.jpg/1200px-Palace_of_Fine_Arts_%2816794p%29.jpg',
      caption: 'San Francisco',
    },
    {
      image:
        'https://i.natgeofe.com/n/f7732389-a045-402c-bf39-cb4eda39e786/scotland_travel_4x3.jpg',
      caption: 'Scotland',
    },
    {
      image:
        'https://www.tusktravel.com/blog/wp-content/uploads/2020/07/Best-Time-to-Visit-Darjeeling-for-Honeymoon.jpg',
      caption: 'Darjeeling',
    },
    {
      image:
        'https://www.omm.com/~/media/images/site/locations/san_francisco_780x520px.ashx',
      caption: 'San Francisco',
    },
    {
      image:
        'https://images.ctfassets.net/bth3mlrehms2/6Ypj2Qd3m3jQk6ygmpsNAM/61d2f8cb9f939beed918971b9bc59bcd/Scotland.jpg?w=750&h=422&fl=progressive&q=50&fm=jpg',
      caption: 'Scotland',
    },
    {
      image:
        'https://www.oyorooms.com/travel-guide/wp-content/uploads/2019/02/summer-7.jpg',
      caption: 'Darjeeling',
    },
  ]
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }
  return (
    <div>
      <div className='background'>
        <Grid lg={12} container item spacing={1} >
          <Grid xl={4} lg={4} md={6} sm={12} xs={12}>
            <div style={{ textAlign: 'center' }}>
              <div
                className="carousel"
                style={{
                  padding: '0 0px',
                }}
              >
                
                <Carousel
                  data={data}
                  time={2000}
                  width="500px"
                  height="700px"
                  left="0px"
                  captionStyle={captionStyle}
                  radius="0px"
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  captionPosition="bottom"
                  automatic={true}
                  dots={true}
                  pauseIconColor="white"
                  pauseIconSize="40px"
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  thumbnails={true}
                  thumbnailWidth="100px"
                  style={{
                    textAlign: '',
                    maxWidth: '445px',
                    maxHeight: '500px',
                    left: '0',
                  }}
                />
              </div>
            </div>
          </Grid>
          <Grid xl={8} lg={8} md={6} sm={12} xs={12}>
            
            <div className="name">
            <motion.div className="hi"
            whileInView={{ y: [-70, 0] }}
            transition={{ ease: "easeIn", duration: 0.5 }}>
                Hello Everyone,</motion.div>
            <div className='typo'>
                
              <motion.div className="iam"
              whileInView={{ x: [90, 0] }}
              transition={{ ease: "easeIn", duration: 0.5 }}><span>I.AM</span></motion.div>
                
              <div className="typing">
              <TypeWriterEffect
                textStyle={{
                  fontFamily: 'Dancing',
                  color: 'black',
                  fontWeight: 600,
                  
                  fontSize: '2.5em',
                }}
                startDelay={2000}
                cursorColor="#3F3D56"
                multiText={[
                  "Roshma Ramachandran 🙋‍♀️",
                  " An Electronics and Communication engineer 👩‍🎓",
                  "A Singer ... 🎤",
                  " A Self Learner ...📚",
                  "Roshma Ramachandran 🙋‍♀️",
                ]}
                loop={true}
                nextTextDelay={1000}
                typeSpeed={30}
              />
              </div>
              <div className='buttons'>
              <div className='btn btn-primary'>About Me</div>
              <div className='btn btn-primary'>Download My resume</div>
              </div>
            </div>
            </div>
            
          </Grid>
          
        </Grid>
      </div>
    </div>
  )
}

export default Head
