
import './App.css';
import Head from './Components/Head/Head';

function App() {
  return (
    <Head/>
  );
}

export default App;
